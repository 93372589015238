<template>
	<div class="legal">
		<TransitionGroup name="fade" appear>
			<h2 :key="1">Terms and Conditions</h2>
			<p :key="2" class="updated">Last updated 2022-08-26</p>
		</TransitionGroup>

		<TransitionGroup appear @before-enter="beforeEnter" @enter="enter">
			<p :key="3" :data-index="1">
				These terms and conditions outline the rules and regulations for the use of Kwizz's Website,
				located at <a href="https://kwizz.io/">https://kwizz.io/</a>. By accessing this website we
				assume you accept these terms and conditions. Do not continue to use Kwizz if you do not
				agree to take all of the terms and conditions stated on this page. The following terminology
				applies to these Terms and Conditions, Privacy Statement and Disclaimer Notice and all
				Agreements: "Client", "You" and "Your" refers to you, the person log on this website and
				compliant to the Company’s terms and conditions. "The Company", "Ourselves", "We", "Our" and
				"Us", refers to our Company. "Party", "Parties", or "Us", refers to both the Client and
				ourselves. All terms refer to the offer, acceptance and consideration of payment necessary
				to undertake the process of our assistance to the Client in the most appropriate manner for
				the express purpose of meeting the Client’s needs in respect of provision of the Company’s
				stated services, in accordance with and subject to, prevailing law of Sweden. Any use of the
				above terminology or other words in the singular, plural, capitalization and/or he/she or
				they, are taken as interchangeable and therefore as referring to same.
			</p>

			<h3 :key="4" :data-index="2">Cookies</h3>
			<p :key="5" :data-index="2">
				We employ the use of cookies. By accessing Kwizz, you agreed to use cookies in agreement
				with the Kwizz's <router-link to="/privacy">Privacy Policy</router-link>. Most interactive
				websites use cookies to let us retrieve the user’s details for each visit. Cookies are used
				by our website to enable the functionality of certain areas to make it easier for people
				visiting our website. Some of our affiliate/advertising partners may also use cookies.
			</p>

			<h3 :key="6" :data-index="3">Using our services</h3>
			<p :key="7" :data-index="3">
				Don't misuse our services. For example, don't interfere with our services or try to access
				them using a method other than the interface and the instructions that we provide. We may
				suspend or stop providing our services to you if you do not comply with our terms or
				policies or if we are investigating suspected misconduct. Using our services does not give
				you ownership of any intellectual property rights in our services or the content you access.
				You may not use content from our services unless you obtain permission from us or are
				otherwise permitted by law. These terms do not grant you the right to use any branding or
				logos used in our services. Don't remove, obscure, or alter any legal notices displayed in
				or along with our services.
			</p>

			<h3 :key="8" :data-index="4">Privacy</h3>
			<p :key="9" :data-index="4">
				Our <router-link to="/privacy">Privacy Policy</router-link> explain how we treat your
				personal data and protect your privacy when you use our services.
			</p>

			<h3 :key="10" :data-index="5">Provided in Good Faith</h3>
			<p :key="11" :data-index="5">
				Our services are provided in good faith and although we endeavor to ensure that the data in
				our services is accurate and obtained from sources believed to be reliable, we do not
				guarantee or warrant the accuracy, authenticity, timeliness, reliability, appropriateness,
				correct sequencing, or completeness of our services; or that our services are free from
				errors or other material defects.
			</p>

			<h3 :key="12" :data-index="6">License</h3>
			<p :key="13" :data-index="6">
				Unless otherwise stated, Kwizz and/or its licensors own the intellectual property rights for
				all material on Kwizz. All intellectual property rights are reserved. You may access this
				from Kwizz for your own personal use subjected to restrictions set in these terms and
				conditions. You must not:
			</p>
			<ul :key="14" :data-index="7">
				<li>Republish material from Kwizz</li>
				<li>Sell, rent or sub-license material from Kwizz</li>
				<li>Reproduce, duplicate or copy material from Kwizz</li>
				<li>Redistribute content from Kwizz</li>
			</ul>
			<p :key="15" :data-index="8">This Agreement shall begin on the date hereof.</p>

			<h3 :key="16" :data-index="9">Disclaimer of Warranties</h3>
			<p :key="17" :data-index="9">
				Other than expressly set out in these terms or additional terms, we do not make any specific
				promises about the services. For example, we do not make any commitments about the content
				within the services, the specific functions of the services, or their reliability,
				availability, or ability to meet your needs. We provide the services "as is".
			</p>

			<h3 :key="18" :data-index="10">About these Terms</h3>
			<p :key="19" :data-index="10">
				We may modify these terms without warning and you should therefore look at the terms
				regularly.
			</p>

			<p :key="20" class="contact" :data-index="11">
				If you have any questions or need more information about the privacy policy, please contact
				me at <a href="mailto:hello@emilcarlsson.se">hello@emilcarlsson.se</a>.
			</p>
		</TransitionGroup>
	</div>
</template>

<script>
import gsap from 'gsap'

export default {
	methods: {
		beforeEnter(el) {
			el.style.opacity = 0
			el.style.transform = 'translateY(30px)'
		},

		enter(el, done) {
			gsap.to(el, {
				opacity: 1,
				y: 0,
				duration: 0.8,
				onComplete: done,
				delay: el.dataset.index * 0.1,
			})
		},
	},
}
</script>

<style lang="scss" scoped></style>
